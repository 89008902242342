import {createSelector} from '@reduxjs/toolkit';
import * as Sentry from '@sentry/nextjs';
import produce from 'immer';

import {byAlphabet} from '~utils/miscUtils';
import {getQueryParams} from '~utils/routeUtil';
import {ReduxState} from '~redux/index';

import {FETCH_USER, PROJECT_ADD_TAG, PROJECT_DELETE_TAG, RESET, USER_ERROR, USER_PROJECT} from '../types';
import {ErrorAction, PayloadAction} from '../types/main';
import {Project, ProjectData, ReduxStateUser, Tag, UserData} from '../types/user';

export const initialState: ReduxStateUser = {
  userId: '',
  customer: '',
  email: '',
  name: '',
  projects: [],
  currentProjectId: '',
  defaultProjectId: '',
  errors: {},
  landingPage: '',
};

export interface TagAddAction extends PayloadAction {
  payload: {project: string; tag: Tag};
}

export interface TagDeleteAction extends PayloadAction {
  payload: {project: string; tag: string};
}

export interface UserAction extends PayloadAction {
  payload: UserData | ProjectData | string;
}

const isProjectInUserData = (projects: Project[] | null, project: string): boolean =>
  Boolean(projects && projects.find((_: Project) => _.id === project));

type UserActions = UserAction | TagAddAction | TagDeleteAction;

const userReducer = (state: ReduxStateUser = initialState, action: UserActions): ReduxStateUser => {
  switch (action.type) {
    case RESET:
      return initialState;
    case PROJECT_DELETE_TAG: {
      const taggedProject = (action as TagDeleteAction).payload.project;
      const tag: string = (action as TagDeleteAction).payload.tag;

      return produce(state, (draft) => {
        draft.projects = state.projects.map((project: Project) => {
          if (project.id !== taggedProject) {
            return project;
          }

          return {
            ...project,
            tags: project.tags.filter((_: Tag) => _.tag !== tag),
          };
        });
        return draft;
      });
    }
    case PROJECT_ADD_TAG: {
      const {project: projectToUpdate, tag} = (action as TagAddAction).payload;

      return produce(state, (draft) => {
        draft.projects = draft.projects.map((project: Project) => {
          if (project.id === projectToUpdate) {
            project.tags = [tag, ...project.tags];
          }
          return project;
        });
        return draft;
      });
    }
    case FETCH_USER: {
      const user = action.payload as UserData;
      return produce(state, (draft) => {
        const {userId, projects, defaultProject, customer, name, email, landingPage} = user;
        const {projectID: projectFromUrl} = getQueryParams(['projectID']);

        const isValidProject = (projectId: string) => isProjectInUserData(user.projects, projectId);
        const currentProjectId =
          projectFromUrl && isValidProject(projectFromUrl)
            ? projectFromUrl
            : isValidProject(state.currentProjectId)
              ? state.currentProjectId
              : defaultProject;

        draft = {
          userId,
          email,
          name,
          customer,
          projects,
          defaultProjectId: defaultProject,
          currentProjectId,
          landingPage,
          errors: {},
        };
        return draft;
      });
    }
    case USER_PROJECT: {
      const project = action.payload as string;
      const newState = produce(state, (draft) => {
        draft.currentProjectId = isProjectInUserData(state.projects, project) ? project : state.currentProjectId;
        return draft;
      });
      Sentry.setTag('projectId', newState.currentProjectId);
      return newState;
    }
    case USER_ERROR: {
      const errorAction = action as ErrorAction;

      const errors: any = {...state.errors};
      errors[errorAction.subtype] = action.payload;

      return {...state, errors: errors};
    }

    default:
      return state;
  }
};

export default userReducer;

export const reducerName = 'user';

export const selectProjects = (state: ReduxState): Project[] => state[reducerName].projects;
export const selectUserId = (state: ReduxState): string => state[reducerName].userId;
export const selectUsername = (state: ReduxState): string | undefined => state[reducerName].name;
export const selectUserEmail = (state: ReduxState): string | undefined => state[reducerName].email;
export const selectCurrentProjectId = (state: ReduxState): string => state[reducerName].currentProjectId;
export const selectDefaultProjectId = (state: ReduxState): string => state[reducerName].defaultProjectId;
export const selectCustomer = (state: ReduxState): string | undefined => state[reducerName].customer;
export const selectLandingPage = (state: ReduxState): string | undefined => state[reducerName].landingPage;

export const selectUser = (state: ReduxState): ReduxStateUser | undefined => {
  if (state[reducerName].userId === '') {
    return undefined;
  } else {
    return state[reducerName];
  }
};

export const selectCurrentProject = createSelector(
  [selectCurrentProjectId, selectProjects],
  (currentProjectId, projects) => {
    return projects.find((_: Project) => _.id === currentProjectId) || null;
  },
);

export const selectCurrentProjectTags = createSelector([selectCurrentProject], (currentProject) => {
  if (!currentProject) {
    return [];
  }
  // Create a copy of the array to avoid mutating the state
  return [...currentProject.tags].sort((tagA, tagB) => tagB.createdAt - tagA.createdAt);
});

export const selectCurrentProjectTagNames = createSelector([selectCurrentProjectTags], (tags) => {
  return tags.map((tag) => tag.tag).sort(byAlphabet);
});

export const selectCurrentTrainingTagNames = createSelector([selectCurrentProject], (currentProject) => {
  if (!currentProject) {
    return [];
  }
  // Create a copy of the array to avoid mutating the state
  return [...currentProject.trainingTags].sort((tagA, tagB) => tagB.createdAt - tagA.createdAt).map((tag) => tag.tag);
});
