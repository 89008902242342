import {Route} from '~utils/routeUtil';

// default class labels
export const CLASS_EMPTY = 'EMPTY';
export const CLASS_OK = 'OK';
export const UNLABELED = 'UNLABELED';
export const ANOMALY = 'ANOMALY';

// In the backend, label IDs are suffixed by the model ID, separated by this separator
// e.g. <label>__<modelID>
export const LABEL_ID_SEPARATOR = '__';

// Retry requests on these status codes (applies to ky)
// see also https://github.com/sindresorhus/ky#retry
export const API_RETRY_STATUS_CODES = [413, 429, 500, 502, 503, 504];
export const API_RETRY_COUNT = 1;

export const IMAGE_SORT_ORDER_DELIMITER = '__';

export const MIN_LABEL_DISTRIBUTION_CHART_HEIGHT = 250;

export const DEFAULT_SHORT_LABEL_CHAR_LENGTH = 40;

export const IMAGE_OVERVIEW_ITEMS_PER_PAGE = 100;
export const MAX_IMAGES_TOTAL = 10000;
export const IMAGES_UPLOAD_CHUNK = 100;
export const TOP_FIRST_N_IMAGES = 1000;
export const THUMBNAIL_BORDER_WIDTH = 4;
export const THUMBNAIL_MAX_HEIGHT = 120;

export const MINIMUM_DATE_FOR_TIMEFRAME = new Date(2020, 0, 1);

export const DONT_SHOW_AGAIN_KEYS = 'dontShowAgainKeys';

export const TIME_FORMAT = 'HHmm';

export const UNKNOWN_MODEL_ID = '__UNKNOWN_MODEL_ID__';

export const NO_DEFECTS_KEY = '#CONDITION_NO_DEFECTS';
export const NO_OBJECTS_KEY = '#CONDITION_NO_OBJECTS';

export const PORTAL_ROOT_ID = 'portal-root';

// The precision is defined by the backend
export const FINETUNE_CONFIDENCE_THRESHOLD_PRECISION = 3;

export const NAVIGATION_MAP: {
  [key: string]: string | null;
} = {
  // [ChildRoute]: ParentRoute
  [Route.modelSettings]: Route.modelSamples,
  [Route.modelSamplesImage]: Route.modelSamples,
  [Route.modelAddSamples]: Route.modelSamples,
  [Route.modelSamples]: Route.models,
  [Route.modelLabelDistribution]: Route.modelSamples,
  [Route.modelFinetune]: Route.modelTraining,
  [Route.modelFinetuneImage]: Route.modelFinetune,
  [Route.modelTraining]: Route.modelSamples,
  [Route.modelLabelNoise]: Route.modelSamples,
  [Route.archiveImage]: Route.archive,
  [Route.tasksSession]: Route.tasks,
  [Route.tasksLiveSession]: Route.tasks,
  [Route.settingsChangelog]: Route.settingsDevices,
};

export const HEATMAP_AVAILABLE_PROJECTS = ['bbl1'];
