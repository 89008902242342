import {useQueryClient} from '@tanstack/react-query';
import isequal from 'lodash.isequal';
import {useRouter} from 'next/router';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {IMAGE_OVERVIEW_ITEMS_PER_PAGE} from '~constants/constants';
import {LabelInstancesQueryKeys} from '~api/label-instances.queries';
import {splitImageOrderBy} from '~utils/miscUtils';
import {imagesOverviewViewModeQueryParam, Route, useQueryParam} from '~utils/routeUtil';
import {
  ImageFilters,
  LabelInstance,
  PaginationAction,
  PaginationActionName,
  PaginationActions,
  ViewMode,
} from '~redux/types/images';
import {selectCurrentProjectId} from '~redux/reducers/userReducer';
import {useAppSelector} from '~redux/index';

import {useImageFilters} from '~components/images/image-overview/imageOverview.hooks';
import {ImageOverviewMode} from '~components/images/utils/image-mode-mixins';
import {useLabelItems} from '~components/models/model.hooks';

export interface LabelInstancesContextValue {
  currentPage: number;
  handlePageChange: (action: PaginationActionName, targetPage: number) => void;
  handleResetPage: () => void;
  currentPagination?: PaginationAction;
  setAvailablePaginationActions: Dispatch<SetStateAction<PaginationActions | undefined>>;
  currentTotalLabelInstances?: number;
  setCurrentTotalLabelInstances: Dispatch<SetStateAction<number | undefined>>;
  activeLabelInstance?: LabelInstance;
  handleSetActiveLabelInstance: (labelInstance: LabelInstance) => void;
  activeViewMode?: ViewMode;
  setActiveViewMode: Dispatch<SetStateAction<ViewMode | undefined>>;
  isPageDirty: boolean;
  setIsPageDirty: Dispatch<SetStateAction<boolean>>;
}

export const LabelInstancesContext = createContext<LabelInstancesContextValue>({} as LabelInstancesContextValue);

export const useLabelInstances = () => {
  const context = useContext(LabelInstancesContext);
  if (!context) {
    throw new Error('useLabelInstancesState must be used within a LabelInstancesProvider');
  }
  return context;
};

export function LabelInstancesProvider({
  children,
  mode = 'model-defect-book',
}: {
  children: ReactNode;
  mode?: ImageOverviewMode;
}) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const projectId = useAppSelector(selectCurrentProjectId);
  const [viewMode, setViewMode] = useQueryParam(imagesOverviewViewModeQueryParam);
  const areViewModesAvailable = [
    Route.modelSamples,
    Route.modelSamplesImage,
    Route.archive,
    Route.archiveImage,
  ].includes(router.pathname as Route);

  const [currentPage, setCurrentPage] = useState(1);
  const [availablePaginationActions, setAvailablePaginationActions] = useState<PaginationActions | undefined>();
  const [currentPagination, setCurrentPagination] = useState<PaginationAction | undefined>();
  const [currentTotalLabelInstances, setCurrentTotalLabelInstances] = useState<number | undefined>();
  const [activeLabelInstance, setActiveLabelInstance] = useState<LabelInstance | undefined>();
  const [activeViewMode, setActiveViewMode] = useState<ViewMode | undefined>(viewMode as ViewMode);
  const modelLabelItems = useLabelItems({from: 'current-model'});
  const [currentFilters, setCurrentFilters] = useState<ImageFilters>();
  const [imageFilters] = useImageFilters(mode, modelLabelItems);
  const [isPageDirty, setIsPageDirty] = useState(false);

  useEffect(() => {
    if (!isequal(currentFilters, imageFilters)) {
      setCurrentFilters(imageFilters);
      setCurrentPagination(undefined);
      setCurrentPage(1);
    }
  }, [currentFilters, imageFilters]);

  useEffect(() => {
    if (!areViewModesAvailable || (viewMode as ViewMode) === 'image') {
      setActiveLabelInstance(undefined);
    }
  }, [areViewModesAvailable, viewMode]);

  useEffect(() => {
    if (areViewModesAvailable && !viewMode && !activeViewMode) {
      setViewMode('image');
      setActiveViewMode('image');
    } else if (areViewModesAvailable) {
      setViewMode(activeViewMode);
    } else {
      setActiveViewMode(undefined);
    }
  }, [activeViewMode, areViewModesAvailable, setViewMode, viewMode]);

  useEffect(() => {
    if (isPageDirty) {
      const payload = {
        filters: imageFilters,
        pagination: {
          ...splitImageOrderBy(imageFilters?.orderBy),
          action: currentPagination,
          count: IMAGE_OVERVIEW_ITEMS_PER_PAGE,
        },
      };
      queryClient.invalidateQueries(
        LabelInstancesQueryKeys.labelInstancesDataFilterPaginated(
          projectId,
          payload.filters,
          {
            sortBy: payload.pagination?.sortBy,
            sortOrder: payload.pagination?.sortOrder,
            count: payload.pagination?.count,
          },
          currentPage,
        ),
      );
      setIsPageDirty(false);
    }
  }, [currentFilters, currentPage, currentPagination, imageFilters, isPageDirty, projectId, queryClient]);

  const handleResetPage = () => {
    setCurrentPage(1);
    setCurrentPagination(undefined);
  };

  const handlePageChange = useCallback(
    async (action: PaginationActionName, targetPage: number) => {
      const paginationAction = availablePaginationActions ? availablePaginationActions[action] : undefined;
      if (paginationAction) {
        const pageOffset = action === PaginationActionName.JUMP ? targetPage - currentPage : undefined;
        setCurrentPagination({...paginationAction, pageOffset});
        setCurrentPage(targetPage);
      }
    },
    [availablePaginationActions, currentPage],
  );

  const handleSetActiveLabelInstance = (labelInstance: LabelInstance) => {
    setActiveLabelInstance(labelInstance);
  };

  const value: LabelInstancesContextValue = useMemo(
    () => ({
      currentPage,
      handlePageChange,
      currentPagination,
      setAvailablePaginationActions,
      handleResetPage,
      currentTotalLabelInstances,
      setCurrentTotalLabelInstances,
      activeLabelInstance,
      handleSetActiveLabelInstance,
      activeViewMode,
      setActiveViewMode,
      isPageDirty,
      setIsPageDirty,
    }),
    [
      activeLabelInstance,
      activeViewMode,
      currentPage,
      currentPagination,
      currentTotalLabelInstances,
      handlePageChange,
      isPageDirty,
    ],
  );

  return <LabelInstancesContext.Provider value={value}>{children}</LabelInstancesContext.Provider>;
}
